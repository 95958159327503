import {Store} from 'pullstate';

export const GPSStore = new Store({
  useGPS: false,
  hasGPS: true,
  hasGPSPermissions: false,
  location: null,
  currentMapFocus: null,
});

window.GPSStore = GPSStore;

export const NetworkStore = new Store({
  loading: false,
  error: null,
});

export const HousesStore = new Store({
  houses: [],
  selectedHouse: null,
  hoveredHouse: null,
});

export const CitySearchStore = new Store({
  cities: [],
  city: null,
});

HousesStore.subscribe(
  s => s.selectedHouse,
  selectedHouse => {
    if (selectedHouse !== null) {
      GPSStore.update(gps => {
        gps.currentMapFocus = [selectedHouse.lat, selectedHouse.lon];
      });
    }
  },
);

CitySearchStore.subscribe(
  s => s.city,
  city => {
    if (city !== null) {
      GPSStore.update(gps => {
        gps.currentMapFocus = [city.lat, city.lon];
      });
    }
  },
);
