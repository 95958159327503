import axios from 'axios';
import {HousesStore, NetworkStore, CitySearchStore, GPSStore} from './stores';

const BASE_URL = 'https://dev.ptruck.de';
// const BASE_URL = 'http://localhost:3000';
// const BASE_URL = 'http://10.0.2.2:3000';
// const BASE_URL = 'http://192.168.0.243:3000';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 7000,
  responseType: 'json',
});

axiosInstance.interceptors.response.use(
  response => {
    if (response.data.errorcode !== 0) {
      throw new Error(response.data.error);
    }

    return response;
  },
  error => Promise.reject(error),
);

export const autocompleteCity = async search => {
  try {
    const response = await axiosInstance.get('/api/cities/autocomplete', {
      params: {search},
    });

    if (response.status === 200) {
      CitySearchStore.update(s => {
        s.cities = response.data.cities;
      });
    } else {
      NetworkStore.update(s => {
        s.error = response.data;
      });
    }
  } catch (err) {
    NetworkStore.update(s => {
      s.error = err.message;
    });
  }
};

export const listHouses = async () => {
  try {
    const response = await axiosInstance.get('/api/houses');

    if (response.status === 200) {
      HousesStore.update(s => {
        s.houses = response.data.houses.filter(
          house => house.name.indexOf('Demo') === -1,
        );
      });
    } else {
      NetworkStore.update(s => {
        s.error = response.data;
      });
    }
  } catch (err) {
    NetworkStore.update(s => {
      s.error = err.message;
    });
  }
};

export const getNearParkingSpots = async (lat, lon) => {
  try {
    const response = await axiosInstance.get('/api/houses', {
      params: {lat, lon},
    });

    if (response.status === 200) {
      HousesStore.update(s => {
        s.houses = response.data.houses.filter(
          house => house.name.indexOf('Demo') === -1,
        );
      });
    } else {
      NetworkStore.update(s => {
        s.error = response.data;
      });
    }
  } catch (err) {
    NetworkStore.update(s => {
      s.error = err.message;
    });
  }
};

GPSStore.subscribe(
  s => s.location,
  location => {
    if (location !== null) {
      getNearParkingSpots(location[0], location[1]);
    }
  },
);
