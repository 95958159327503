import React from 'react';

import {autocompleteCity} from './Api';
import CitySearchViewContent from './CitySearchViewContent';

import {CitySearchStore, GPSStore} from './stores';

const CitySearchView = ({onCloseDialog, navigation}) => {
  const {cities} = CitySearchStore.useState(s => ({
    cities: s.cities,
  }));
  const [search, setSearch] = React.useState('');

  const onSearchTextChange = text => {
    setSearch(text);
    autocompleteCity(text);
  };

  const onCityChosen = city => {
    GPSStore.update(s => {
      s.location = [city.lat, city.lon];
    });
    CitySearchStore.update(s => {
      s.city = city;
      s.cities = [];
    });
    // setUseGPS(false);
    // setCity(city);
    setSearch(city.name);
    // setCities([]);
  };

  // const onUseGPS = () => {
  //   enableGPS();
  //   navigation.goBack();
  // };

  return (
    <CitySearchViewContent
      cities={cities}
      onCityChosen={onCityChosen}
      search={search}
      onSearchTextChange={onSearchTextChange}
    />
  );
};

export default CitySearchView;
