import React from 'react';
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  ZoomControl,
} from 'react-leaflet';
import L from 'leaflet';
import 'daemonite-material/js/material.min.js';
import Modal from 'react-bootstrap/Modal';
import Sidebar from './Sidebar';
import {HousesStore, GPSStore} from './stores';

const HouseImagesCarousel = ({images}) => {
  images = images.map((image, index) => (
    <div key={image} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
      <img className="d-block w-100" alt="Autohof Bild" src={image} />
    </div>
  ));

  return (
    <div id="carousel" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">{images}</div>
      <a
        className="carousel-control-prev"
        href="#carousel"
        role="button"
        data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carousel"
        role="button"
        data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};

const HouseModal = () => {
  const house = HousesStore.useState(s => s.selectedHouse);

  let free = house?.free_spots;
  if (free < 0) {
    free = 0;
  }

  return (
    <Modal
      size="lg"
      show={house !== null}
      onHide={() =>
        HousesStore.update(s => {
          s.selectedHouse = null;
        })
      }>
      <Modal.Header closeButton>
        <Modal.Title>{house?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {house?.images?.length > 0 && (
          <HouseImagesCarousel images={house.images} />
        )}

        <table className="table">
          <tbody>
            <tr>
              <td>Freie Parkplaetze</td>
              <td>{free}</td>
            </tr>
            <tr>
              <td>Distanz</td>
              <td>
                {house?.distance === null
                  ? 'unbekannt'
                  : house?.distance?.toFixed() + ' km'}
              </td>
            </tr>
          </tbody>
        </table>

        <h4>{house?.name}</h4>

        <div>{house?.description}</div>
      </Modal.Body>

      <Modal.Footer>
        <button
          onClick={() =>
            HousesStore.update(s => {
              s.selectedHouse = null;
            })
          }
          type="button"
          className="btn btn-secondary"
          data-
          dismiss="modal">
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const greenIcon = new L.Icon({
  iconUrl: require('./images/parking_point_green.svg'),
  iconRetinaUrl: require('./images/parking_point_green.svg'),
  iconSize: new L.Point(40, 60),
});

const orangeIcon = new L.Icon({
  iconUrl: require('./images/parking_point_orange.svg'),
  iconRetinaUrl: require('./images/parking_point_orange.svg'),
  iconSize: new L.Point(40, 60),
});

const greyIcon = new L.Icon({
  iconUrl: require('./images/parking_point_grey.svg'),
  iconRetinaUrl: require('./images/parking_point_grey.svg'),
  iconSize: new L.Point(40, 60),
});

const redIcon = new L.Icon({
  iconUrl: require('./images/parking_point_red.svg'),
  iconRetinaUrl: require('./images/parking_point_red.svg'),
  iconSize: new L.Point(40, 60),
});

const MapComponent = () => {
  const map = React.useRef();

  const {houses, hoveredHouse} = HousesStore.useState(s => ({
    houses: s.houses,
    hoveredHouse: s.hoveredHouse,
  }));

  let [focus, location] = GPSStore.useState(s => [
    s.currentMapFocus,
    s.location,
  ]);

  const markers = houses.map(house => {
    let houseIcon = greenIcon;
    if (house.free_spots <= 0) {
      houseIcon = redIcon;
    } else if (house.free_spots < 10) {
      houseIcon = orangeIcon;
    } else if (hoveredHouse !== null && hoveredHouse.id === house.id) {
      houseIcon = greyIcon;
    }

    return (
      <Marker
        icon={houseIcon}
        onClick={() =>
          HousesStore.update(s => {
            s.selectedHouse = house;
          })
        }
        key={house.id}
        position={[house.lat, house.lon]}>
        <Tooltip>{house.name}</Tooltip>
      </Marker>
    );
  });

  if (location === null) {
    location = [51.133333333333, 10.41666];
  }

  if (focus === null) {
    location = [51.133333333333, 10.41666];
  }

  return (
    <div className="row mx-0 flex-column flex-md-row" style={{flex: 1}}>
      <Sidebar />

      <div className="col-md-9 p-0 map-container">
        <Map
          ref={map}
          center={focus}
          zoom={7}
          boxZoom={false}
          zoomControl={false}>
          <ZoomControl position="bottomright" />

          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {
            <Marker position={location}>
              <Popup>Ihre Position.</Popup>
            </Marker>
          }
          <HouseModal />
          {markers}
        </Map>
      </div>
    </div>
  );
};

export default MapComponent;
