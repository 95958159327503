import React from 'react';

import logo from './images/PTruck_logo_slim.webp';
import road from './images/road1.webp';

const CitySearchViewContent = ({
  cities,
  onCityChosen,
  search,
  onSearchTextChange,
}) => {
  let items = cities.map(city => {
    let postcode = city.postcode;
    if (postcode.length > 28) {
      postcode = postcode.substring(0, 28) + ' ...';
    }
    return (
      <li
        key={city.id}
        onClick={() => onCityChosen(city)}
        className="list-group-item"
        style={{cursor: 'pointer'}}>
        <span>
          {city.name} - {postcode}
        </span>
      </li>
    );
  });

  const onSearchChange = event => {
    onSearchTextChange(event.target.value);
  };

  // <li
  //   onClick={() => setUseGPS(true)}
  //   className="list-group-item"
  //   style={{cursor: 'pointer'}}>
  //   <Icon path={mdiCrosshairsGps} size={0.6} color="black" />
  //   <span>GPS verwenden</span>
  // </li>

  return (
    <React.Fragment>
      <div className="d-block d-md-none" style={{height: '100%'}}>
        <div
          style={{
            backgroundImage: `url(${road})`,
            backgroundSize: 'cover',
          }}>
          <img alt="PTruck Logo" src={logo} className="mt-4 img-fluid" />

          <div className="search-container">
            <input
              type="text"
              className="form-control"
              placeholder="Ort suchen"
              onChange={onSearchChange}
              value={search}
            />

            <ul className="autocomplete-list" style={{paddingLeft: 0}}>
              {search.length > 0 && items}
            </ul>
          </div>
        </div>
      </div>

      <div className="d-none d-md-block" style={{height: '100%'}}>
        <div
          className="p-3 pb-5"
          style={{
            backgroundImage: `url(${road})`,
            backgroundSize: 'cover',
          }}>
          <div className="row">
            <div className="col-md-4">
              <img alt="Logo" src={logo} className="mt-4 img-fluid" />
            </div>
          </div>

          <div className="search-container">
            <input
              type="text"
              className="form-control"
              placeholder="Ort suchen"
              onChange={onSearchChange}
              value={search}
            />

            <ul className="autocomplete-list" style={{paddingLeft: 0}}>
              {search.length > 0 && items}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CitySearchViewContent;
