import React from 'react';
import CitySearchView from './CitySearchView';

import {HousesStore} from './stores';

const HouseItem = ({house}) => {
  let distance = house.distance;
  if (distance === null) {
    distance = '';
  } else {
    distance = distance.toFixed();
  }

  const selected = HousesStore.useState(s => s.selectedHouse);

  let cls = 'house-item';
  if (selected !== null && selected.id === house.id) {
    cls += ' house-item-active';
  }

  return (
    <div
      className={cls}
      onMouseEnter={() => {
        HousesStore.update(s => {
          s.hoveredHouse = house;
        });
      }}
      onMouseLeave={() => {
        HousesStore.update(s => {
          if (s.hoveredHouse?.id === house.id) {
            s.hoveredHouse = null;
          }
        });
      }}
      onClick={() =>
        HousesStore.update(s => {
          s.selectedHouse = house;
        })
      }>
      <div>
        <b>{house.name}</b>
      </div>
      <div>in {distance} km</div>
      <div>noch {house.free_spots} frei</div>
    </div>
  );
};

export default () => {
  const houses = HousesStore.useState(s => s.houses);
  // const city = CitySearchStore.useState(s => s.city);
  const houseItems = houses.map(house => (
    <HouseItem key={house.id} house={house} />
  ));

  return (
    <React.Fragment>
      <div className="d-block d-md-none" style={{flex: 0.3}}>
        <CitySearchView />
      </div>

      <div className="d-none d-md-block col-md-3 p-0 sidebar">
        <div
          className="m-0"
          style={{display: 'flex', height: '100%', flexDirection: 'column'}}>
          <div style={{flex: 0.3}}>
            <CitySearchView />
          </div>

          <div style={{flex: 0.7}}>{houseItems}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
