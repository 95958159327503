import React from 'react';
import 'daemonite-material/js/material.min.js';
import 'daemonite-material/css/material.min.css';
import './page-style.css';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import {listHouses} from './Api';
import CitySearchView from './CitySearchView';
import Map from './Map';
import {GPSStore} from './stores';

function App() {
  React.useEffect(() => {
    listHouses();
  }, []);

  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        GPSStore.update(s => {
          s.location = [pos.coords.latitude, pos.coords.longitude];
          s.currentMapFocus = s.location;
        });
      });
    }
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Map />
        </Route>
        <Route path="/autocomplete">
          <CitySearchView />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
